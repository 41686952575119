var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "hpc__container hidden-print",
      class: { hpc__container_opened: _vm.opened },
      attrs: { "aria-hidden": !_vm.opened },
    },
    [
      _c(
        "div",
        {
          staticClass: "hpc__help-menu-button hpc__help-menu-button_help",
          attrs: { role: "link", "aria-label": "Help Menu" },
          on: { click: _vm.helpClick },
        },
        [
          _c("div", { staticClass: "hpc__help-icon_lifesaver hpc__help-icon" }),
          _vm._v(" "),
          _c("div", {
            staticClass: "hpc__help-icon-text",
            domProps: { innerHTML: _vm._s(_vm.helpButtonLabel) },
          }),
        ]
      ),
      _vm._v(" "),
      _vm.isAh
        ? _c(
            "div",
            {
              staticClass: "hpc__help-menu-button hpc__help-menu-button_ah",
              attrs: { role: "link", "aria-label": "Help Menu" },
              on: { click: _vm.ahClick },
            },
            [
              _c("div", { staticClass: "hpc__help-icon_ah hpc__help-icon" }),
              _vm._v(" "),
              _c("div", { staticClass: "hpc__help-icon-text" }, [
                _vm._v("Portal"),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "vhm-content__container",
          class: { "vhm-content__container_opened": _vm.opened },
        },
        [
          _c("h3", { staticClass: "vhm-content__header" }, [
            _c(
              "a",
              {
                attrs: {
                  tabindex: _vm.opened ? 0 : -1,
                  href: "https://multime.freshdesk.com/support/home",
                  target: "_blank",
                },
              },
              [_vm._v("Explore\n            our knowledge base")]
            ),
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "vhm-content__header" }, [
            _c(
              "a",
              {
                attrs: {
                  tabindex: _vm.opened ? 0 : -1,
                  href: "https://multime.freshdesk.com/support/solutions/44000410991",
                  target: "_blank",
                },
              },
              [_vm._v("Getting\n                Started")]
            ),
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "vhm-content__header" }, [
            _c(
              "a",
              {
                attrs: {
                  tabindex: _vm.opened ? 0 : -1,
                  href: "https://multime.freshdesk.com/support/solutions/44000439834",
                  target: "_blank",
                },
              },
              [_vm._v("Basic Toolkit\n                Guides")]
            ),
          ]),
          _vm._v(" "),
          _c("ol", { staticClass: "vhm-content__list" }, [
            _c("li", [
              _c("span", { staticClass: "vhm-content__list-item" }, [
                _c("i", {
                  staticClass: "fa fa-link",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      tabindex: _vm.opened ? 0 : -1,
                      href: "https://multime.freshdesk.com/support/solutions/folders/44000649720",
                      target: "_blank",
                    },
                  },
                  [_vm._v("My Circle")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("span", { staticClass: "vhm-content__list-item" }, [
                _c("i", {
                  staticClass: "fa fa-link",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      tabindex: _vm.opened ? 0 : -1,
                      href: "https://multime.freshdesk.com/support/solutions/folders/44000649722",
                      target: "_blank",
                    },
                  },
                  [_vm._v("My Wiki")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("span", { staticClass: "vhm-content__list-item" }, [
                _c("i", {
                  staticClass: "fa fa-link",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      tabindex: _vm.opened ? 0 : -1,
                      href: "https://multime.freshdesk.com/support/solutions/folders/44000649724",
                      target: "_blank",
                    },
                  },
                  [_vm._v("My Diary")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("span", { staticClass: "vhm-content__list-item" }, [
                _c("i", {
                  staticClass: "fa fa-link",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      tabindex: _vm.opened ? 0 : -1,
                      href: "https://multime.freshdesk.com/support/solutions/folders/44000649723",
                      target: "_blank",
                    },
                  },
                  [_vm._v("My " + _vm._s(_vm._f("customTerm")("Goals")))]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("span", { staticClass: "vhm-content__list-item" }, [
                _c("i", {
                  staticClass: "fa fa-link",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      tabindex: _vm.opened ? 0 : -1,
                      href: "https://multime.freshdesk.com/support/solutions/folders/44000685152",
                      target: "_blank",
                    },
                  },
                  [_vm._v("My Calendar")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("span", { staticClass: "vhm-content__list-item" }, [
                _c("i", {
                  staticClass: "fa fa-link",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      tabindex: _vm.opened ? 0 : -1,
                      href: "https://multime.freshdesk.com/a/solutions/folders/44000649721",
                      target: "_blank",
                    },
                  },
                  [_vm._v("My Media")]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.isBuddy || _vm.isAh
            ? _c("h3", { staticClass: "vhm-content__header" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      tabindex: _vm.opened ? 0 : -1,
                      href: "https://multime.freshdesk.com/support/solutions/44000439846",
                      target: "_blank",
                    },
                  },
                  [_vm._v("Advanced & Buddy\n                Toolkit Guides")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isBuddy || _vm.isAh
            ? _c("ol", { staticClass: "vhm-content__list" }, [
                _c("li", [
                  _c("span", { staticClass: "vhm-content__list-item" }, [
                    _c("i", {
                      staticClass: "fa fa-link",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          tabindex: _vm.opened ? 0 : -1,
                          href: "https://multime.freshdesk.com/a/solutions/articles/44002092532",
                          target: "_blank",
                        },
                      },
                      [_vm._v("Buddy Guide")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("span", { staticClass: "vhm-content__list-item" }, [
                    _c("i", {
                      staticClass: "fa fa-link",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          tabindex: _vm.opened ? 0 : -1,
                          href: "https://multime.freshdesk.com/support/solutions/folders/44000649735",
                          target: "_blank",
                        },
                      },
                      [_vm._v("My Circle")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("span", { staticClass: "vhm-content__list-item" }, [
                    _c("i", {
                      staticClass: "fa fa-link",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          tabindex: _vm.opened ? 0 : -1,
                          href: "https://multime.freshdesk.com/support/solutions/folders/44000649757",
                          target: "_blank",
                        },
                      },
                      [_vm._v("My Wiki")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("span", { staticClass: "vhm-content__list-item" }, [
                    _c("i", {
                      staticClass: "fa fa-link",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          tabindex: _vm.opened ? 0 : -1,
                          href: "https://multime.freshdesk.com/support/solutions/folders/44000649754",
                          target: "_blank",
                        },
                      },
                      [_vm._v("My Diary")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("span", { staticClass: "vhm-content__list-item" }, [
                    _c("i", {
                      staticClass: "fa fa-link",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          tabindex: _vm.opened ? 0 : -1,
                          href: "https://multime.freshdesk.com/support/solutions/folders/44000649736",
                          target: "_blank",
                        },
                      },
                      [_vm._v("My " + _vm._s(_vm._f("customTerm")("Goals")))]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isAh
            ? _c("h3", { staticClass: "vhm-content__header" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      tabindex: _vm.opened ? 0 : -1,
                      href: "https://multime.freshdesk.com/support/solutions/44000445439",
                      target: "_blank",
                    },
                  },
                  [_vm._v("Portal Admin\n                Guides")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isAh
            ? _c("ol", { staticClass: "vhm-content__list" }, [
                _c("li", [
                  _c("span", { staticClass: "vhm-content__list-item" }, [
                    _c("i", {
                      staticClass: "fa fa-link",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          tabindex: _vm.opened ? 0 : -1,
                          href: "https://multime.freshdesk.com/support/solutions/folders/44000657996",
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          "Creating and managing accounts & roles in your Portal"
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("span", { staticClass: "vhm-content__list-item" }, [
                    _c("i", {
                      staticClass: "fa fa-link",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          tabindex: _vm.opened ? 0 : -1,
                          href: "https://multime.freshdesk.com/support/solutions/folders/44000657997",
                          target: "_blank",
                        },
                      },
                      [_vm._v("Portal Admins tools & templates")]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("h3", { staticClass: "vhm-content__header" }, [
            _vm._v("Ask a question or report an issue"),
          ]),
          _vm._v(" "),
          _c("ol", { staticClass: "vhm-content__list" }, [
            _c("li", [
              _c("span", { staticClass: "vhm-content__list-item" }, [
                _c("i", {
                  staticClass: "fa fa-question-circle-o",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      tabindex: _vm.opened ? 0 : -1,
                      href: "https://multime.freshdesk.com/support/tickets/new",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(
                      "Please send us a message and we will respond to you within 24 hours. "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "vhm-content__header" }, [
            _c(
              "a",
              {
                staticStyle: { "text-decoration": "underline" },
                attrs: {
                  tabindex: _vm.opened ? 0 : -1,
                  href: "https://www.multime.com/contact-1",
                  target: "_blank",
                },
              },
              [_vm._v("Contact\n                Us")]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }