import Vue from 'vue';
import HelpDesk from './HelpDesk';

(function () {
    const vm = new Vue({
        el: '#vue-help-panel',
        render(createElement) {
            const {props: attrs} = this;
            return createElement(HelpDesk, {attrs});
        },
    });
})();