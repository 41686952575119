<template>
    <div class="hpc__container hidden-print" :class="{'hpc__container_opened':opened}" :aria-hidden="!opened">
        <div class="hpc__help-menu-button hpc__help-menu-button_help" @click="helpClick" role="link"
             aria-label="Help Menu">
            <div class="hpc__help-icon_lifesaver hpc__help-icon"></div>
            <div class="hpc__help-icon-text" v-html="helpButtonLabel"></div>
        </div>
        <div class="hpc__help-menu-button hpc__help-menu-button_ah"
             v-if="isAh"
             @click="ahClick"
             role="link"
             aria-label="Help Menu">
            <div class="hpc__help-icon_ah hpc__help-icon"></div>
            <div class="hpc__help-icon-text">Portal</div>
        </div>
        <div class="vhm-content__container" :class="{'vhm-content__container_opened': opened}">
            <h3 class="vhm-content__header"><a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/support/home" target="_blank">Explore
                our knowledge base</a></h3>
            <h3 class="vhm-content__header">
                <a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/support/solutions/44000410991" target="_blank">Getting
                    Started</a>
            </h3>
            <h3 class="vhm-content__header">
                <a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/support/solutions/44000439834" target="_blank">Basic Toolkit
                    Guides</a>
            </h3>
            <ol class="vhm-content__list">
                <li><span class="vhm-content__list-item">
                <i class="fa fa-link" aria-hidden="true"></i>
                <a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/support/solutions/folders/44000649720"
                   target="_blank">My Circle</a></span>
                </li>
                <li><span class="vhm-content__list-item">
                <i class="fa fa-link" aria-hidden="true"></i>
                <a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/support/solutions/folders/44000649722"
                   target="_blank">My Wiki</a></span>
                </li>
                <li><span class="vhm-content__list-item">
                <i class="fa fa-link" aria-hidden="true"></i>
                <a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/support/solutions/folders/44000649724"
                   target="_blank">My Diary</a></span>
                </li>
                <li><span class="vhm-content__list-item">
                <i class="fa fa-link" aria-hidden="true"></i>
                <a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/support/solutions/folders/44000649723"
                   target="_blank">My {{ "Goals" | customTerm }}</a></span>
                </li>
                <li><span class="vhm-content__list-item">
                <i class="fa fa-link" aria-hidden="true"></i>
                <a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/support/solutions/folders/44000685152" target="_blank">My Calendar</a></span>
                </li>
                <li><span class="vhm-content__list-item">
                <i class="fa fa-link" aria-hidden="true"></i>
                <a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/a/solutions/folders/44000649721"
                   target="_blank">My Media</a></span>
                </li>
            </ol>

            <h3 class="vhm-content__header" v-if="isBuddy||isAh">
                <a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/support/solutions/44000439846" target="_blank">Advanced & Buddy
                    Toolkit Guides</a>
            </h3>
            <ol class="vhm-content__list" v-if="isBuddy||isAh">
                <li><span class="vhm-content__list-item">
                <i class="fa fa-link" aria-hidden="true"></i>
                <a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/a/solutions/articles/44002092532" target="_blank">Buddy Guide</a></span>
                </li>
                <li><span class="vhm-content__list-item">
                <i class="fa fa-link" aria-hidden="true"></i>
                <a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/support/solutions/folders/44000649735"
                   target="_blank">My Circle</a></span>
                </li>
                <li><span class="vhm-content__list-item">
                <i class="fa fa-link" aria-hidden="true"></i>
                <a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/support/solutions/folders/44000649757"
                   target="_blank">My Wiki</a></span>
                </li>
                <li><span class="vhm-content__list-item">
                <i class="fa fa-link" aria-hidden="true"></i>
                <a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/support/solutions/folders/44000649754"
                   target="_blank">My Diary</a></span>
                </li>
                <li><span class="vhm-content__list-item">
                <i class="fa fa-link" aria-hidden="true"></i>
                <a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/support/solutions/folders/44000649736"
                   target="_blank">My {{ "Goals" | customTerm }}</a></span>
                </li>
            </ol>

            <h3 class="vhm-content__header" v-if="isAh">
                <a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/support/solutions/44000445439" target="_blank">Portal Admin
                    Guides</a></h3>
            <ol class="vhm-content__list" v-if="isAh">
                <li><span class="vhm-content__list-item">
                <i class="fa fa-link" aria-hidden="true"></i>
                <a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/support/solutions/folders/44000657996" target="_blank">Creating and managing accounts &amp; roles in your Portal</a></span>
                </li>
                <li><span class="vhm-content__list-item">
                <i class="fa fa-link" aria-hidden="true"></i>
                <a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/support/solutions/folders/44000657997" target="_blank">Portal Admins tools &amp; templates</a></span>
                </li>
            </ol>
            <h3 class="vhm-content__header">Ask a question or report an issue</h3>
            <ol class="vhm-content__list">
                <li><span class="vhm-content__list-item">
                <i class="fa fa-question-circle-o" aria-hidden="true"></i>
                <a :tabindex="opened?0:-1" href="https://multime.freshdesk.com/support/tickets/new" target="_blank">Please send us a message and we will respond to you within 24 hours. </a></span>
                </li>
            </ol>
            <h3 class="vhm-content__header">
                <a :tabindex="opened?0:-1" href="https://www.multime.com/contact-1" target="_blank" style="text-decoration: underline">Contact
                    Us</a>
            </h3>
        </div>
    </div>
</template>

<script>
    import {CurrentUserClass} from "../../shared/CurrentUser";

    export default {
        name: "HelpDesk",
        data() {
            return {
                opened: false,
            };
        },
        computed: {
            isAh() {
                return new CurrentUserClass().is_ah();
            },
            isBuddy() {
                return new CurrentUserClass().is_buddy();
            },
            showAHButton() {
                return false;
            },
            helpButtonLabel() {
                if (this.opened) {
                    return "Close<br/>Menu";
                }
                return 'Help<br/>Menu';
            }
        },
        methods: {
            ahClick() {
                document.location.href = new CurrentUserClass().ah_link();
            },
            helpClick() {
                this.opened = !this.opened;
            },
        }
    };
</script>

<style scoped lang="scss">
  .hpc {
    &__container {
      position: fixed;
      background-color: #5A9BD5;
      z-index: 1021;
      overflow: visible !important;

      @media all and (max-width: 599px) {
        height: 58px;
        width: 100%;
        left: 0;
        bottom: 0;
        transition: height ease 0.7s;
        &_opened {
          height: 80%;
        }
      }

      @media all and (min-width: 600px) {
        height: 100%;
        top: 0;
        right: 0;
        width: 12px;
        transition: width ease 0.7s;
        &_opened {
          width: 50%;
        }
      }
    }

    &__help-menu-button {
      @media all and (max-width: 599px) {
        float: right;
        height: 58px;
        min-width: 105px;
        background-color: #5A9BD5;
        top: -3px;
        z-index: 1021;
        cursor: pointer;
        padding-top: 6px;
        display: inline-block;

        &_help {
          left: 15%;
        }
        &_ah {
          left: 45%;
        }
      }

      @media all and (min-width: 600px) {
        position: relative;
        height: 98px;
        width: 60px;
        background-color: #5A9BD5;
        right: 50px;
        z-index: 1021;
        cursor: pointer;
        padding-top: 6px;
        border-radius: 5px;

        &_help {
          top: 35%;
        }

        &_ah {
          top: 38%;
        }
      }
    }

    &__help-icon {
      height: 50px;
      width: 50px;
      background-size: 50px 50px;
      background-position: center;
      background-repeat: no-repeat;
      background-repeat: no-repeat;
      margin: auto;

      @media all and (max-width: 599px) {
        display: inline-block;
      }

      &_lifesaver {
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20enable-background%3D%22new%200%200%2024%2024%22%20xml%3Aspace%3D%22preserve%22%3E%3Cellipse%20fill%3D%22%23FFFFFF%22%20cx%3D%2212%22%20cy%3D%2212.031%22%20rx%3D%2211.625%22%20ry%3D%2211.656%22%2F%3E%3Cpath%20fill%3D%22%23E6493B%22%20d%3D%22M12%2C1.001c6.075%2C0%2C11%2C4.925%2C11%2C10.999s-4.925%2C10.999-11%2C10.999S1%2C18.074%2C1%2C12S5.925%2C1.001%2C12%2C1.001z%20M12%2C6.5%20c-3.037%2C0-5.5%2C2.462-5.5%2C5.5s2.462%2C5.5%2C5.5%2C5.5s5.5-2.462%2C5.5-5.5S15.038%2C6.5%2C12%2C6.5z%20M12%2C2.146c-1.375%2C0-2.685%2C0.275-3.879%2C0.773%20l1.41%2C3.157c0.76-0.318%2C1.594-0.493%2C2.469-0.493s1.709%2C0.175%2C2.469%2C0.493l1.41-3.157C14.686%2C2.421%2C13.375%2C2.146%2C12%2C2.146z%20M5.583%2C12%20c0-0.875%2C0.175-1.708%2C0.492-2.468L2.919%2C8.121C2.421%2C9.314%2C2.146%2C10.625%2C2.146%2C12s0.275%2C2.686%2C0.773%2C3.879l3.156-1.411%20C5.759%2C13.708%2C5.583%2C12.875%2C5.583%2C12z%20M21.854%2C12c0-1.375-0.271-2.686-0.771-3.879l-3.156%2C1.411c0.317%2C0.76%2C0.492%2C1.593%2C0.492%2C2.468%20s-0.175%2C1.708-0.492%2C2.468l3.156%2C1.411C21.58%2C14.686%2C21.854%2C13.375%2C21.854%2C12z%20M12%2C21.854c1.375%2C0%2C2.686-0.272%2C3.879-0.771%20l-1.41-3.157c-0.76%2C0.318-1.594%2C0.492-2.469%2C0.492s-1.709-0.174-2.469-0.492l-1.41%2C3.157C9.315%2C21.579%2C10.625%2C21.854%2C12%2C21.854z%22%2F%3E%3C%2Fsvg%3E");
        @media all and (max-width: 599px) {
          position: relative;
          left: 5px;
        }
      }

      &_ah {
        background-image: url('./ah_1.png');
      }

    }

    &__help-icon-text {
      @media all and (min-width: 600px) {
        text-align: center;
        color: white;
        font-size: 15px;
        font-weight: bolder;
        line-height: 16px;
      }

      @media all and (max-width: 599px) {
        display: inline-block;
        text-align: center;
        color: white;
        font-size: 15px;
        font-weight: bolder;
        line-height: 16px;
        position: relative;
        top: 10px;
        float: right;
        margin-right: 5px;
      }
    }
  }

  .vhm-content {
    @media (max-width: 599px) {
      &__container {
        opacity: 0;
        transition-property: opacity;
        transition-duration: 0.3s;
        transition-delay: 0;

        &_opened {
          opacity: 1;
          transition-delay: 0.7s;
        }

        padding-left: 5px;
        max-height: 85%;
        overflow-y: auto;
        width: 97%;

        color: white;
        margin-top: 25px;

        a {
          color: white;
        }

        li {
          margin-bottom: 6px;
        }

      }


      &__list {
        max-height: 35vh;
        overflow: auto;
        padding-left: 40px;
      }

      &__list-item {
        font-size: 16px;
        line-height: 16px;
      }

      &__header {
        margin-top: 5px;
        font-size: 19px;

        a {
          text-decoration: underline;
        }
      }
    }

    @media (min-width: 600px) {
      &__container {
        opacity: 0;
        transition-property: opacity;
        transition-duration: 0.3s;
        transition-delay: 0;

        &_opened {
          opacity: 1;
          transition-delay: 0.7s;
        }

        padding-left: 5px;
        max-height: 95%;
        width: 100%;
        overflow-y: auto;
        position: absolute;
        top: 5%;

        ol {
          padding-left: 40px;
        }

        color: white;
        margin-left: 25px;

        a {
          color: white;
        }

        li {
          margin-bottom: 5px;
        }
      }

      &__list-item {
        font-size: 23px;
      }

      &__header {
        margin-top: 10px;
        font-size: 24px;

        a {
          text-decoration: underline;
        }
      }
    }

  }
</style>
